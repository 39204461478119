import React from 'react'
import PropTypes from 'prop-types'
import { QRCodeSVG } from 'qrcode.react'
import { Link } from '@mui/material'
import BaseInstructions from './base'

const manifestUrl = (projectId, buildId) =>
  `itms-services://?action=download-manifest;url=https://api.expo.dev/v2/projects/${projectId}/builds/${buildId}/manifest.plist`

const IosInstructions = ({ build }) => {
  if (!build || !build.projectId || !build.id) {
    return null
  }

  return (
    <BaseInstructions
      version={build.version}
      runtimeVersion={build.runtimeVersion}
      header='To install the app on iPhone device'
      footer={
        <>
          Scan the above code on your iPhone.
          <br />
          Don't forget that{' '}
          <Link
            href='https://helpling.atlassian.net/wiki/spaces/FRON/pages/3470393405/Adding+new+iOS+devices+to+the+provisioning+profile'
            target='_blank'
            rel='noreferrer'
          >
            your device needs to be added to the provisioning profile
          </Link>
          .
        </>
      }
    >
      <QRCodeSVG value={manifestUrl(build.projectId, build.id)} size='150' />
    </BaseInstructions>
  )
}

IosInstructions.propTypes = {
  build: PropTypes.shape({
    id: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired
  })
}

export default IosInstructions
