import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

/**
 * A React component that renders a button which, when clicked, opens a confirmation dialog.
 *
 * @param {Object} props - The properties object.
 * @param {React.ElementType} [props.Component=Button] - The component to render as the button.
 * @param {string} props.messageTitle - The title of the confirmation dialog.
 * @param {string} props.messageContent - The content of the confirmation dialog.
 * @param {string} [props.buttonYesTitle='Continue'] - The text for the confirmation button.
 * @param {string} [props.buttonNoTitle='Cancel'] - The text for the cancellation button.
 * @param {function} props.onClick - The original onClick handler for the button.
 * @param {function} [props.onBeforeConfirm] - A function to call before opening the confirmation dialog. If it returns `false`, the dialog will not open. If it returns `true`, the original onClick handler will be called immediately.
 * @param {Object} props - Additional props to pass to the button component.
 * @param {React.Ref} ref - The ref to pass to the button component.
 *
 * @returns {React.Element} The rendered ConfirmButton component.
 */
const ConfirmButton = React.forwardRef(
  (
    {
      Component = Button,
      messageTitle,
      messageContent,
      buttonYesTitle = 'Continue',
      buttonNoTitle = 'Cancel',
      onClick: originalOnClick,
      onBeforeConfirm,
      ...props
    },
    ref
  ) => {
    const [open, setOpen] = useState(false)
    // TODO: better API
    const onOpen = useCallback(
      (e) => {
        if (onBeforeConfirm) {
          const result = onBeforeConfirm()

          if (result === false) {
            return
          }

          if (result === true) {
            originalOnClick(e)
            return
          }
        }

        e.stopPropagation()
        setOpen(true)
      },
      [onBeforeConfirm, originalOnClick]
    )
    const onClose = useCallback((e) => {
      e.stopPropagation()
      setOpen(false)
    }, [])
    const onAccept = useCallback(
      (e) => {
        e.stopPropagation()
        originalOnClick(e)
        onClose(e)
      },
      [originalOnClick, onClose]
    )

    return (
      <>
        <Component {...props} onClick={onOpen} ref={ref} />
        <Dialog open={open} onClose={onClose}>
          {!!messageTitle && <DialogTitle id='alert-dialog-title'>{messageTitle}</DialogTitle>}
          <DialogContent>
            <DialogContentText>{messageContent}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{buttonNoTitle}</Button>
            <Button onClick={onAccept} autoFocus>
              {buttonYesTitle}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
)

ConfirmButton.propTypes = {
  Component: PropTypes.elementType,
  messageTitle: PropTypes.node,
  messageContent: PropTypes.node,
  buttonYesTitle: PropTypes.node,
  buttonNoTitle: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  onBeforeConfirm: PropTypes.func
}

export default ConfirmButton
