export const mergeIntoCollection = (collection, element, finder = (item) => item.id === element.id) => {
  const index = collection.findIndex(finder)

  if (!collection || collection.length === 0) {
    return [element]
  }

  if (index === -1) {
    return [...collection, element]
  }

  return [...collection.slice(0, index), element, ...collection.slice(index + 1)]
}
