import React from 'react'

export function addToEndAdornment (endAdornment, content) {
  if (!endAdornment) {
    return content
  }

  const children = React.Children.toArray(endAdornment.props.children)
  children.push(content)
  return React.cloneElement(endAdornment, {}, children)
}
