import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { styled, useTheme } from '@mui/material/styles'
import { observer } from 'mobx-react'
import { Box, Collapse, Grid, Typography, Container, List, Paper, Tooltip, useMediaQuery, Avatar } from '@mui/material'
import InstanceCard from '../../instance_card'
import { getFavicon, getDeploymentStatus, getColors } from '../../../utils/build'
import Title from '../../title'
import JiraIssue from '../../jira_issue'
import StatusLabel from './status_label'
import NotFound from './not_found'
import Build from './build'
import { useQuery } from '../../../utils/apollo'
import { DEPLOYMENT_UPDATED_SUBSCRIPTION, FETCH_DEPLOYMENT } from '../../../api/deployments'
import groupBy from 'lodash/groupBy'
import relativeDate from 'tiny-relative-date'
import User from '../../user'
import { useStore } from '../../../store'
import FullpageLoader from '../../fullpage_loader'
import RedeployButton from './redeploy_button'
import { mergeIntoCollection } from '../../../utils/collection'

const BuildList = styled(List)`
  & .MuiListItem-container {
    display: flex;
    align-items: center;
  }
`

const StyledCollapse = styled(Collapse)`
  width: 100%;
`

const Cards = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
  justify-content: space-between;
`

const DeploymentInfo = styled('div')`
  padding: ${(props) => props.theme.spacing(2)};
  margin: ${(props) => props.theme.spacing(0, 0, 3, 0)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.spacing(2)};
`

const DeploymentUserInfo = styled('div')`
  display: flex;
  align-items: center;
`

const Actions = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Details = observer(({ deployment, status, id, builds }) => {
  const {
    users: { get }
  } = useStore()

  const user = get(deployment.username)
  const instances = Object.keys(builds).map((name) => ({ name }))

  return (
    <>
      <Paper elevation={1}>
        <DeploymentInfo>
          <DeploymentUserInfo>
            <Avatar src={user?.avatarUrl} sx={{ mr: 2 }} />
            <Box>
              <Typography variant='subtitle2'>
                Requested by{' '}
                <b>
                  <User email={deployment.username} />
                </b>
              </Typography>
              <Typography variant='subtitle2'>
                <Tooltip title={new Date(deployment.createdAt).toLocaleString()}>
                  <span>{relativeDate(deployment.createdAt)}</span>
                </Tooltip>
              </Typography>
            </Box>
          </DeploymentUserInfo>
          <Actions>
            <RedeployButton id={id} issues={deployment.jiraIssues} instances={instances} sx={{ marginRight: 2 }} />
            <StatusLabel status={status} />
          </Actions>
        </DeploymentInfo>
      </Paper>
    </>
  )
})

const BuildPage = () => {
  const { id } = useParams()

  const { data, error, loading, subscribeToMore } = useQuery(FETCH_DEPLOYMENT, {
    variables: { id }
  })

  useEffect(
    () =>
      subscribeToMore({
        document: DEPLOYMENT_UPDATED_SUBSCRIPTION,
        variables: { id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data?.deploymentUpdated?.build) {
            return prev
          }

          const { build } = subscriptionData.data.deploymentUpdated
          const existingBuilds = prev.deployment.builds.nodes

          return {
            ...prev,
            deployment: {
              ...prev.deployment,
              builds: {
                ...prev.deployment.builds,
                nodes: mergeIntoCollection(
                  existingBuilds,
                  build,
                  (b) => b.app === build.app && b.appType === build.appType && b.instance.name === build.instance.name
                )
              }
            }
          }
        }
      }),
    [id]
  )

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const deployment = data?.deployment
  const status = getDeploymentStatus(deployment)

  if (deployment === null || error) {
    return <NotFound />
  }

  const builds = groupBy(deployment?.builds?.nodes || [], (build) => build?.instance?.name)

  const [appbarColor, extraBackgroundColor] = getColors(status)

  if (loading) {
    return <FullpageLoader />
  }

  // sort alphabetically first by app, then by appType
  const buildSort = (a, b) => {
    if (a.app < b.app) return -1
    if (a.app > b.app) return 1
    if (a.appType < b.appType) return -1
    if (a.appType > b.appType) return 1
    return 0
  }

  return (
    <Container>
      <Title title={`Deployment #${id}`} favicon={getFavicon(status)} colors={[appbarColor, extraBackgroundColor]} />

      <StyledCollapse in={!loading && !!deployment}>
        {deployment && <Details id={id} deployment={deployment} status={status} builds={builds} />}

        <Cards>
          {status && (
            <Grid container spacing={3}>
              <Grid item md={7} sm={12} xs={12} sx={{ order: mobile ? 2 : 1 }}>
                {Object.entries(builds).map(([instanceId, builds]) => (
                  <InstanceCard instance={instanceId} key={instanceId} {...builds[0].instance} gutter withLinks>
                    <BuildList dense>
                      {builds.sort(buildSort).map((build) => (
                        <Build key={`${build.app}.${build.appType}`} build={build} instanceId={instanceId} />
                      ))}
                    </BuildList>
                  </InstanceCard>
                ))}
              </Grid>
              <Grid item md={5} sm={12} xs={12} sx={{ order: mobile ? 1 : 2 }}>
                {deployment &&
                  deployment.jiraIssues.map((jiraIssue) => <JiraIssue key={jiraIssue.key} id={id} issue={jiraIssue} />)}
              </Grid>
            </Grid>
          )}
        </Cards>
      </StyledCollapse>
    </Container>
  )
}

export default observer(BuildPage)
