import React, { useState } from 'react'
import { TextField, Card, CardContent, CircularProgress, Typography, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import JiraDetails from './jira_details'
import { useQuery } from '@apollo/client'
import { FETCH_JIRA_ISSUES_FOR_AUTOCOMPLETE } from '../api/jira'
import { normalizeJiraIssueKey, useCreateJiraIssues } from '../utils/jira'
import { addToEndAdornment } from '../utils/mui'

const StyledCard = styled(Card)`
  & .MuiCardContent-root {
    padding: 16px;
  }
`

const IssueName = styled(Typography)`
  margin-right: ${(props) => props.theme.spacing(0.5)};
`

const JiraForm = ({ children, ...props }) => {
  const { loading, data } = useQuery(FETCH_JIRA_ISSUES_FOR_AUTOCOMPLETE)
  const { jiraIssues, addJiraIssue } = useCreateJiraIssues()

  const [issue, setIssue] = useState('')
  const [selectedOption, setSelectedOption] = useState(null)

  const filterOptions = createFilterOptions({
    stringify: (option) => `${option.key} ${option.title} ${option.team} ${option.url}`
  })

  const onSubmit = async (event, submittedValue) => {
    event.preventDefault()
    try {
      const value = submittedValue || issue || selectedOption
      const url = normalizeJiraIssueKey(typeof value === 'string' ? value : value.key)
      addJiraIssue(url)
      setSelectedOption(null)
      setIssue('')
    } catch (exception) {
      console.log(exception)
    }
  }

  const setSelectedOptionAndSubmit = (event, value) => {
    setSelectedOption(value)
    return onSubmit(event, value)
  }

  const suggestions = data?.jiraIssues?.filter((issue) => !jiraIssues.includes(issue.key)) || []

  return (
    <StyledCard {...props}>
      <CardContent>
        <form onSubmit={onSubmit}>
          <Autocomplete
            autoComplete
            freeSolo
            disabled={loading}
            filterOptions={filterOptions}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.url)}
            options={suggestions}
            includeInputInList
            value={selectedOption}
            onChange={(e, value) => setSelectedOptionAndSubmit(e, value)}
            inputValue={issue}
            onInputChange={(e, value) => setIssue(value)}
            fullWidth
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  fullWidth
                  variant='outlined'
                  placeholder='Copy JIRA url here or start typing the ticket number, title or team name...'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: addToEndAdornment(
                      params.InputProps.endAdornment,
                      loading ? <CircularProgress size={24} /> : null
                    )
                  }}
                />
              )
            }}
            renderOption={(props, option) => {
              return (
                <Grid container key={option.key} {...props}>
                  <IssueName variant='subtitle1' display='inline'>
                    {option.key}
                  </IssueName>
                  <Typography variant='subtitle1' display='inline' color='textSecondary'>
                    {option.title}
                  </Typography>
                  <JiraDetails team={option.team} status={option.status} priority={option.priority} />
                </Grid>
              )
            }}
          />
        </form>
      </CardContent>
    </StyledCard>
  )
}

JiraForm.propTypes = {}

export default JiraForm
